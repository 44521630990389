<template>
  <section>
    <e-filters
      :searching="fetching"
      @search="getData"
      @reset="resetFiltersLocal"
    >
      <FormulateForm
        name="filtersPriceTable"
        @submit="getData"
      >
        <div>
          <b-row>
            <b-col md="6">
              <FormulateInput
                id="name"
                v-model="filters.name"
                :label="$t('Nome')"
                :placeholder="$t('Nome')"
              />
            </b-col>
          </b-row>
        </div>
      </FormulateForm>
    </e-filters>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="getData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>

      <b-table
        id="price-list-table"
        ref="priceTableTable"
        show-empty
        responsive
        striped
        class="bordered"
        :empty-text="getEmptyTableMessage($tc('PRICE_TABLE.NAME'), 'female')"
        :fields="fields"
        :items="priceTables"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <template #cell(action)="row">
          <e-grid-actions
            :is-active="row.item.active"
            :show-update="$can('Update', 'PriceTable')"
            :show-delete="$can('Delete', 'PriceTable')"
            :show-activate="$can('Activate', 'PriceTable')"
            :show-deactivate="$can('Deactivate', 'PriceTable')"
            @update="showUpdateSidebar(row)"
            @delete="deletePriceTable(row)"
            @activate="activateDeactivatePriceTable(row)"
            @deactivate="activateDeactivatePriceTable(row)"
          />
        </template>
        <template #cell(active)="row">
          <e-status-badge :status="row.item.active" />
        </template>
      </b-table>

      <b-row class="pt-1">
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="price-list-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <price-table-sibebar
      ref="pricetable_sidebar"
      @save="getData"
    />

    <fab
      v-if="$can('Create', 'PriceTable')"
      :main-tooltip="$t('Adicionar Tabela de preços')"
      @click="showCreateSidebar"
    />
  </section>
</template>

<script>
import { BRow, BCol, BPagination, BTable } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  EPageSizeSelector,
  EPaginationSummary,
  EFilters,
  EGridActions,
  EStatusBadge,
} from '@/views/components'
import fab from '@/views/components/FAB.vue'
import { mapActions, mapState } from 'vuex'
import PriceTableSibebar from './PriceTableSidebar.vue'

export default {
  name: 'ProductListList',
  components: {
    BRow,
    BCol,
    BCardActions,
    BPagination,
    BTable,
    EPaginationSummary,
    EPageSizeSelector,
    fab,
    EFilters,
    EGridActions,
    EStatusBadge,
    PriceTableSibebar,
  },

  mixins: [],

  data() {
    return {
      fetching: false,
      edit: false,
      saving: false,
    }
  },

  computed: {
    ...mapState('pages/sale/priceTable', ['priceTables', 'paging', 'sorting', 'filters']),
    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          class: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Id'),
          key: 'id',
          tdClass: 'text-right',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Nome'),
          key: 'name',
          formatter: (value, _, item) => `${value} ${item.isDefault ? '(Padrão)' : ''}`,
          thClass: 'text-center',
          tdClass: 'text-left',
          sortable: true,
        },
        {
          label: this.$t('Desconto'),
          key: 'retailDiscount',
          tdClass: 'text-right',
          formatter: value => this.$options.filters.percentage(value, 4),
          thStyle: { width: '150px' },
          sortable: true,
        },
        {
          label: this.$t('Status'),
          key: 'active',
          class: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
      ]
    },
  },

  mounted() {
    this.getData()
  },

  methods: {
    ...mapActions('pages/sale/priceTable', [
      'fetchPriceTables',
      'resetFilters',
      'setCurrentPage',
      'setPageSize',
    ]),

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchPriceTables()
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    resetFiltersLocal() {
      this.resetFilters()
      this.setCurrentPage(1)
      this.getData()
    },

    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },

    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    showCreateSidebar() {
      this.$refs.pricetable_sidebar.show()
    },

    async showUpdateSidebar(row) {
      const { id } = row.item
      this.$refs.pricetable_sidebar.show({ id })
    },

    async deletePriceTable(priceTable) {
      const { id } = priceTable.item
      console.log(id)
      try {
        const confirm = await this.confirm()
        if (confirm) {
          await this.$http.delete(`/api/price-tables/${id}`)
          this.getData()
          this.showSuccess({
            message: this.$t('Tabela de preço deletada com sucesso'),
          })
        }
      } catch (error) {
        this.showError({ error })
      }
    },

    async activateDeactivatePriceTable(row) {
      try {
        const confirm = await this.confirm()
        if (confirm) {
          const path = row.item.active ? 'deactivate' : 'activate'
          await this.$http({
            url: `/api/price-tables/${path}/${row.item.id}`,
            method: 'PATCH',
          })
          this.getData()
          this.showSuccess({
            message: this.$t(`${row.item.active === true ? 'Inativado' : 'Ativado'} com sucesso`),
          })
        }
      } catch (error) {
        this.showError({ error })
      }
    },
  },
}
</script>

<style></style>
